footer {
  min-height: 120px;
  background: rgba(217, 217, 217, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-wrap: wrap;
  margin-top: 96px;
  color: var(--muted-color);
  position: relative;
}

footer a {
  color: var(--muted-color);
  transition: var(--transition);
  cursor: pointer;
  padding: 0 8px;
}

footer a:hover {
  color: var(--text-color);
}

.footer-social {
  position: absolute;
  right: 90px;
}
html[dir='rtl'] .footer-social {
  right: auto;
  left: 90px;
}
.footer-social a {
  margin: 0 10px;
  display: inline-block;
  padding: 16px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.footer-social img {
  width: 40px;
}

@media only screen and (max-width: 1700px) {
  .footer-social {
    right: 30px;
  }

  html[dir='rtl'] .footer-social {
    right: auto;
    left: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .footer-social {
    position: relative;
    right: auto;
    margin-top: 16px
  }

  .footer-social img {
    width: 24px;
  }

  .footer-social a {
    padding: 8px;
  }

  footer {
    padding: 16px;
    margin-top: 0;
    height: auto;
    font-size: 14px;
  }
}