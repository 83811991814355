#section_1 {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  margin-top: 0;
}

#section_1 .section-title {
  max-width: 900px;
  font-size: 48px;
  line-height: 56px;
}

#section_1 .btn-gradient {
  margin-top: 54px;
}

.section_1-text {
  margin-top: 24px;
  max-width: 600px;
}

#section_1 .logo {
  height: 125px;
  width: auto;
}

.slider-wrap {
  display: flex;
  padding-left: 35px;
  position: relative;
  /*pointer-events: none;*/
  align-items: center;
}

html[dir='rtl'] .slider-wrap{
  padding-left: 0;
  padding-right: 35px;
}
.slider {
  margin-top: -150px;
  min-width: 400px;
  width: 400px;
  height: 900px;
  align-items: center;
  overflow: hidden;
  /*height: 900px;*/
  /*overflow: hidden;*/
}

.slider-item {
  height: 300px;
  position: relative;
  transition: all 1s linear;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.slider-wrap-last-releases {
  position: absolute;
  height: 20px;
  right: 100%;
  transform: rotate(-90deg);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--muted-color);
}
html[dir='rtl'] .slider-wrap-last-releases{
  right: auto;
  left: 100%;
}
.slider-item img {
  transition: all 1s linear;
  object-fit: cover;
  position: absolute;
}

.slider-item.before img {
  transform: rotate(15deg) scale(0.6) translate(150px, 20px);
}

.slider-item.after img {
  transform: rotate(-15deg) scale(0.6) translate(150px, -20px);
}

.slider-item.next {
  transform: translate(100px, -150px);
}

.slider-item.next img {
  transform: rotate(-30deg) scale(0.4);
}

.slider-item.active img {
  transform: rotate(0deg) scale(1) translate(0, 0);
}

.slider-info {
  align-self: center;
  width: 300px;
  min-width: 300px;
  height: 300px;
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
html[dir='rtl']  .slider-info {

  margin-left: 0;
  margin-right: 32px;
}
.custom-sider {
  width: 300px;
  min-width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: relative;
}


.custom-sider-item {
  z-index: 1;
  position: absolute;
  transition: all 1s linear;
  opacity: 0;
  filter: grayscale(100%);

}

.custom-sider-item:after {
  content: '';
  opacity: 0;
  transition: all 1s linear;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: var(--gradient);
  filter: blur(50px);
  border-radius: 40px;
  z-index: -1;
}

.custom-sider-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.custom-sider-item_0 {
  transform: translate(180px, -425px) scale(0.33) rotate(25deg);
}

.custom-sider-item_1 {
  cursor: pointer;
  opacity: 1;
  transform: translate(80px, -280px) scale(0.66) rotate(15deg);
}

.custom-sider-item_2 {
  filter: grayscale(0);
  opacity: 1;
  transform: translate(0, 0) scale(1) rotate(0);
}

.custom-sider-item_2:after {
  opacity: 1;
}

.custom-sider-item_3 {
  cursor: pointer;
  opacity: 1;
  transform: translate(80px, 280px) scale(0.66) rotate(-15deg);
}

.custom-sider-item_4 {
  transform: translate(180px, 425px) scale(0.33) rotate(-25deg);
}

.custom-sider-item:hover {
  filter: grayscale(0);
}

.slider-info-platform {
  font-size: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 4px 8px;
  font-family: var(--font-bold);
  margin-right: 8px;
}

.slider-info-platform svg {
  height: 16px;
  width: 100%;
}

.slider-info-track-name {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.slider-info-track-name-hidden {
  position: absolute;
  opacity: 0;
}

.slider-info-track-name.visible .slider-info-track-name-visible {
  transition: var(--transition-1s);
  opacity: 0;
}

.slider-info-track-author {
  height: 24px;
}

.slider-info-track-name.visible .slider-info-track-name-hidden {
  transition: var(--transition-1s);
  opacity: 1;
}

.section-text-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 350px;
  padding-top: 40px;
}
.section-text-wrap:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 24px;
  background: linear-gradient(to top, var(--main-bg-color) 0%, transparent 100%);
}
.section-text-wrap:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 40px;
  background: linear-gradient(to bottom, var(--main-bg-color) 0%, transparent 100%);
  z-index: 1;
}
.section-text-wrap .section-text-item:last-child {
  /*position: absolute;*/
  transform: translateY(200%);
}
.section-text-pagination {
  position: absolute;
  bottom: -12px;
  display: flex;
  margin: 0 -4px;
}
.section-text-pagination-item {
  transition: var(--transition);
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 0 4px;
  cursor: pointer;
}
.section-text-pagination-item.active {
  background-color: #fff;
  width: 12px;
}
.section-text-item {
  position: absolute;
  transform: translateY(0);
  transition: all .6s ease;
}

.section-text-wrap.second .section-text-item {
  transform: translateY(-200%);
}

.section-text-wrap.second .section-text-item:last-child {
  /*position: absolute;*/
  transform: translateY(0);
}

.section-text-slider {
  max-width: 100vw;
  margin: 0 -24px;
}

.section-text-slider-item {
  width: 100%;
  padding: 0 24px;
}

.tracks-slick {
  width: 100vw;
  margin: 0 -40px;
}

.tracks-slick-item {
  /*min-width: calc(100vw - 48px) !important;*/

  padding: 0 8px;
}

.tracks-slick-item-content {
  background: rgba(255, 255, 255, 0.1);
  padding: 8px;
  display: flex;
}

.tracks-slick-item-content img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  object-fit: cover;
}

@media only screen and (max-width: 1200px) {
  #section_1 .section-title {
    font-size: 40px;
    line-height: 48px;
  }
  .custom-sider, .slider-info {
    width: 200px;
    min-width: 200px;
    height: 200px;
  }

  .custom-sider-item_1 {
    transform: translate(55px, -190px) scale(0.66) rotate(15deg);
  }

  .custom-sider-item_3 {
    transform: translate(55px, 190px) scale(0.66) rotate(-15deg);
  }

  .custom-sider-item_0 {
    transform: translate(130px, -300px) scale(0.33) rotate(25deg);
  }

  .custom-sider-item_4 {
    transform: translate(130px, 300px) scale(0.33) rotate(-25deg);
  }
}


@media only screen and (max-width: 992px) {
  #section_1 .section-title {
    font-size: 24px;
    line-height: 32px;
  }
  #section_1 {
    min-height: auto;
    flex-direction: column;
  }

  .section_1-text {
    margin-top: 16px;
  }

  #section_1 .logo {
    margin-bottom: 24px;
  }

  .tracks-slick {
    margin: 0 -24px;
  }

  .slider-info-track-name {
    min-height: auto;
  }

  .slider-info-track-author {
    height: auto;
  }

}

@media only screen and (max-width: 768px) {
  #section_1 .section-title {
    font-size: 16px;
    line-height: 24px;
  }
  .section-text-slider {
    margin: 0 -16px;
  }

  .section-text-slider-item {
    padding: 0 16px;
  }

  .tracks-slick {
    margin: 0 -16px;
  }
}