#section_4 {
  position: relative;
  padding-bottom: 0px;
}

.section_4-items {
  position: relative;
  margin-top: 48px;
  width: 1012px;
  height: 726px;

  display: flex;
  justify-content: center;
}

.section_4-items:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 500px;
  height: 600px;
  background: var(--gradient);
  filter: blur(50px);
  border-radius: 40px;
}

.section_4-items-wrap {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
}
.section_4-content {
  flex: 0;
  padding-bottom: 272px;
}
.section_4-items-wrap:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, var(--main-bg-color) 15%, rgba(7, 6, 12, 0) 100%);
  height: 124px;
  pointer-events: none;
}

.section_4-item {
  padding: 32px;
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box, var(--gradient) border-box;
  border-radius: 40px;
  border: 1px solid transparent;
  position: absolute;
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_4-item_1 {
  left: 0;
  top: 0;
}

.section_4-item_2 {
  right: 0;
  top: 150px;
}

.section_4-item_2:after {
  content: '';
  position: absolute;
  background: url("../../assets/images/line_1.svg") no-repeat center;
  width: 68px;
  height: 40px;
  top: -40px;
  left: -14px;
}

.section_4-item_3 {
  top: 400px;
}
.section_4-item_3:after {
  content: '';
  position: absolute;
  background: url("../../assets/images/line_2.svg") no-repeat center;
  width: 68px;
  height: 40px;
  top: -40px;
  transform: translateX(-28px);
}
.section_4-item-content {
  position: relative;
  z-index: 1;
}

.section_4-item-title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  align-items: center;
}

.section_4-pagination {
  position: absolute;
  left: 16px;
  bottom: 80px;
}

.section_4-text_2 {
  max-width: 935px;
}

.section_4-text_3 {
  max-width: 575px;
}

.section_4-title_2 {
  margin-top: 120px;
  margin-bottom: 24px;
}

.section_4-title_3 {
  margin-bottom: 24px;
  margin-top: 332px;
}

.section_4-sider {
  flex: 1;
  /*margin-top: 48px;*/
  padding-left: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: visible;
}
html[dir='rtl']  .section_4-sider {
  padding-left: 0;
  padding-right: 12px;
}

.section_4-btn {
  overflow: visible;
  border-radius: 40px;
  border: 1px solid transparent;
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box, var(--gradient) border-box;
  position: sticky;
  right: 0;
  top: 160px;
}

.section_4-btn:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--gradient);
  filter: blur(50px);
  border-radius: 40px;
  z-index: 1;
}

.section_4-btn-content {
  position: relative;
  z-index: 1;
  border-radius: 40px;
  padding: 32px;
  background-color: var(--main-bg-color);
}

.section_4-btn a {
  margin-top: 68px;
  max-width: 100%;
  border-radius: 40px;
}

.section_4-wave {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 270px;
  background: url("../../assets/images/line_3.svg") no-repeat center;
  bottom: -48px;
}
html[dir='rtl'] .section_4-wave {
  transform:rotateX(180deg);
}
.section_4-wrap {
  display: flex;
}


@media only screen and (max-width: 1700px) {
  .section_4-items {
    flex: 0;
    width: 712px;
  }
  .section_4-item {
    width: 350px;
  }
  .section_4-btn {
    max-width: 500px;
    align-self: center;
  }
}
@media only screen and (max-width: 1200px) {
  .section_4-items {
    flex: 0;
    width: 572px;
  }
  .section_4-item {
    width: 280px;
    padding: 24px;
    border-radius: 32px;
  }
  .section_4-item-title {
    padding-bottom: 24px;
  }
  .section_4-btn {
    align-self: flex-end;
  }
}
@media only screen and (max-width: 992px) {
  .section_4-items {
    margin-top: 24px;
    width: 100%;
    height: auto;
    justify-content: flex-start;
  }
  .section_4-items:before {
    display: none;
  }
  .section_4-wrap {
   display: block;
  }
  .section_4-items-wrap {
    height: 500px;
  }
  .section_4-item_3 {
    top: 320px;
  }
  .section_4-item {
    padding: 16px;
    position: absolute;
    border-bottom: 0;
    border-radius: 24px 24px 0 0;
    width: calc(50% - 6px);
  }
  .section_4-pagination {
    bottom: 20px;
  }
  .section_4-title_3 {
    margin-top: 40px;
  }
  .section_4-wave {
    display: none;
  }
  .section_4-content {
    padding-bottom: 40px;

  }
  .section_4-sider {
    padding-left: 0;
    padding-right: 0;
    /*margin-top: 40px;*/
  }
  .section_4-btn {
    position: relative;
    margin-top: 0;
    top: 0;
    border-radius: 25px;
    align-self: flex-start;
    background: none;
  }
  .section_4-btn-content {
    padding: 16px;
    border-radius: 24px;
    background-color: #1E46E8;
  }
  .section_4-title_2 {
    margin-top: 40px;
  }
  .section_4-btn a {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 576px) {
  .section_4-item-title {
    padding-bottom: 24px;
  }
  .section_4-items-wrap {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
  .section_4-item {
    height: auto;
    top: auto;
    position: relative;
    border-bottom: 0;
    border-radius: 24px 24px 0 0;
    width: 100%;
    padding-bottom: 64px;
  }
  .section_4-item:before {
    content: '';
    position: absolute;
    left: -2px;
    bottom: 0;
    right: -2px;
    height: 64px;
    background: linear-gradient(to top, var(--main-bg-color) 20%, rgba(7, 6, 12, 0) 100%);
  }
  .section_4-item_2:after {
    background-image: url("../../assets/images/line_4.svg");
    left: 0;
  }
  .section_4-item_3:after {
    right: -28px;
    background-image: url("../../assets/images/line_5.svg");
  }
  .section_4-btn {
    max-width: 100%;
  }
  .section_4-title_2 {
    margin-top: 55px;
  }
  .section_4-pagination {
    bottom: 0px;
  }
}

@media only screen and (max-width: 375px) {

}