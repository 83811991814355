::-webkit-scrollbar, iframe body::-webkit-scrollbar, .body-inside-iframe::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
  background-color: transparent !important;
  /*border: 1px solid #d5d5d5 !important;*/
}

::-webkit-scrollbar-thumb, iframe body::-webkit-scrollbar-thumb, .body-inside-iframe::-webkit-scrollbar-thumb {
  background-color: var(--border-color) !important;
  border: 2px solid var(--border-color) !important;
}

html {
  scroll-behavior: smooth;
  width: 100vw;
  /*overflow: hidden;*/
}

body {
  overscroll-behavior-y: none;
  position: relative;
  width: 100vw;
  color: var(--text-color);
  font-family: var(--font-refular);
  font-size: 20px;
  line-height: 30px;
  background-color: var(--main-bg-color);
  overflow-x: hidden;
  margin: 0;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  align-items: center;
}

main {
  display: flex;
  flex-direction: column;
  padding: 0 100px 52px;
  flex: 1;
  max-width: 1728px;
  overflow: visible;
}

.no-scroll {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
}
.btn {
  font-size: 20px;
  border-radius: 40px;
  font-family: var(--font-bold);
  background: rgba(255, 255, 255, 0.1);
  border: none;
  transition: var(--transition);
  position: relative;
  overflow: hidden;
}
.btn:active {
  box-shadow: none;
}
.btn-primary:focus  {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.1);
}
.btn-primary:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  background: var(--gradient);
  transition: var(--transition);
  opacity: 0;
}
.btn-primary:hover, .btn-primary:active:focus  {
  box-shadow: none;
  background: none;
}
.btn-primary:hover:after, .btn-primary:active:focus:after  {
  opacity: 1;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  pointer-events: none;
  background: rgba(255, 255, 255, .1);
  color:rgba(255, 255, 255, .1);
}
.btn > div{
  z-index: 1;
  position: relative;
}
.btn-gradient {
  outline: none;
  height: 80px;
  border-radius: 40px;
  font-family: var(--font-bold);
  background: var(--gradient);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  overflow: hidden;
  position: relative;
  padding: 0 24px;
  border: none;
  color: var(--text-color);
  max-width: 437px;
  font-size: 20px;
  line-height: 30px;
}

.btn-gradient:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  transition: var(--transition);
}
.btn-gradient:hover:before  {
  opacity: 1;
}
.btn-gradient:active:focus, .btn-primary:focus  {
   /*background: var(--gradient);*/
}
.btn-gradient:disabled {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}
ul {
  margin: 0;
  padding-left: 20px;
}

ul li {
  padding-bottom: 8px;
}

p {
  margin-bottom: 0;
  letter-spacing: 0.01em;
}

a {
  color: var(--text-color);
  text-decoration: none;
  outline: none;
}

a:hover, a:active {
  outline: none;
  color: var(--text-color);
}

.bold {
  font-family: var(--font-bold);
}

.bold-16 {
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 24px;
}

.muted {
  color: var(--muted-color);
}


.muted-12 {
  font-size: 12px;
  line-height: 17px;
  color: var(--muted-color);
}

.section-title {
  font-size: 40px;
  line-height: 48px;
  font-family: var(--font-bold);
}

.text-gradient {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-1 {
  background: linear-gradient(100.86deg, #CC4DDF 0%, #5CB1E1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-2 {
  background: linear-gradient(100.86deg, #5CB1E1 0%, #2652E8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-animated {
  background-size: 300% auto;
  animation: move-bg 3s infinite linear alternate;
}
@keyframes move-bg {
  to {
    background-position: 100% 0;
  }
}

.pointer-gradient {
  position: relative;
  transition: background 1s ease-out;

  overflow: hidden;
  display: flex;
}

.pointer-gradient > div {
  position: relative;
  z-index: 1;
}

.pointer-gradient:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--gradient);
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
  transition: var(--transition);
}

.pointer-gradient:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--gradient);
  transition: var(--transition);
  opacity: 0;
  pointer-events: none;
}

.pointer-gradient:hover {
  color: #fff;
}

.pointer-gradient:hover:before {
  opacity: 0;
}

.pointer-gradient:hover:after {
  opacity: 1;
}

.cur-pointer {
  cursor: pointer;
}

.section-title-icon {
  display: inline-flex;
  margin-right: 12px;
  position: relative;
  top: -3px;
}

section {
  margin-top: 120px;
}
.react-responsive-modal-overlay {
  background: rgba(45, 45, 45, 0.64);
}
.react-responsive-modal-modal {
  padding: 40px 36px 32px;
  border-radius: 40px;
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box, var(--gradient) border-box;
  width: 780px;
  max-width: 90%;
}
.modal-title {
  font-size: 40px;
  line-height: 48px;
  font-family: var(--font-bold);
  text-align: center;
}
.hello-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  max-width: 480px;
  font-size: 32px;
  line-height: 40px;
  font-family: var(--font-bold);
  text-align: center;
}
.hello-page img{
  width: 210px;
  height: auto;
  margin-bottom: 32px;
}
.slick-dots {
 text-align: left;
  margin-left: 24px;
  bottom: -32px;
}
.slick-dots li  {
  padding: 0 !important;
  min-width: 8px;
  width: 8px;
  height: 8px;

}
.slick-dots li button {
  padding: 0 !important;
  min-width: 8px;
  width: 8px;
  height: 8px;
}
.slick-dots li.slick-active button:before {
  background: #fff;
  opacity: 1;
}
.slick-dots li button:before {

  background: rgba(255, 255, 255, 0.4);
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 16px;
  opacity: 1;

}
.platform-icon {
  display: flex;
  height: 100%;
  align-items: center;
}
.platform-icon  svg path{
  transition: var(--transition);
  /*height: 40px;*/
}
.platform-icon:hover path{
  fill-opacity: 1;
  stroke-opacity: 1;
}


.Toastify__toast-theme--light {
  background: var(--main-bg-color);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  border-radius: 0;
  border: 1px solid #43454B;
}
.Toastify__progress-bar--error {
  background: var(--danger);
}


html[dir='rtl'] .ms-4 {
  margin-left: 0;
  margin-right: 24px;
}
html[dir='rtl'] .me-3{
  margin-right: 0;
  margin-left: 16px;
}

html[dir='rtl'] .section-title-icon{
  margin-right: 0;
  margin-left: 12px;
}
html[dir='rtl'] .slick-dots {
  margin-left: 0;
  margin-right: 24px;
}